<template>
  <div>
    <v-form>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="form.sync_ids"
                placeholder="Select 0 or more methods"
                :items="methodsOptions"
                item-text="title"
                item-value="value"
                label="Methods"
                outlined
                chips
                multiple
                hide-details
                @change="handleSync"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <!-- <v-select
              v-model="form.type"
              placeholder="Display Section"
              :items="typeOptions"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details
            ></v-select> -->
            </v-col>
          </v-row>
        </v-card-text>

        <v-alert
          v-for="msg in errors"
          :key="msg"
          color="warning"
        >
          {{ msg }}
        </v-alert>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import commonStore from '@/modules/common/store'
import store from '@/store'
import axios from '@axios'
import { } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    selectedIds: {
      // default selected ids
      type: Array,
      default() {
        return []
      },
    },
    syncBasePath: {
      // example: /merchant/products/1/sync-methods
      type: String,
      required: true,
    },
    syncAble: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    syncAble: 'handleSync',
  },

  setup(props, { emit }) {
    // const typeOptions = [
    //   { title: 'Service Category', value: 'service' },
    //   { title: 'Tangible Category', value: 'tangible' },
    // ]
    if (!commonStore.state.methods.length) {
      store.dispatch('common/fetchAllMethods', {
        take: 999,
      })
    }

    const methodsOptions = computed(() => commonStore.state.methods.map(meth => ({ title: meth.name.en, value: meth.id })))

    const initialForm = {
      sync_ids: props.selectedIds,
    }

    const form = ref({ ...initialForm })

    const handleSync = () => {
      if (!props.syncAble) return

      axios.post(props.syncBasePath, form.value).then(res => {
        console.log('sync-ed', res)
        emit('change')

        return Promise.resolve(res)
      })
    }

    const making = ref(false)

    return {
      form,

      // typeOptions,
      methodsOptions,
      handleSync,

      making,
    }
  },
}
</script>
