import { notifyErrors } from '@/assets/js/utils'
import { fetchCurrentMerchant } from '@api/merchant/merchant'
import { ref } from '@vue/composition-api'

export default function useMerchantView() {
  const merchantData = ref(null)
  const loading = ref(false)

  // fetch data
  const loadMerchant = () => {
    merchantData.value = null
    loading.value = true

    return fetchCurrentMerchant()
      .then(res => {
        merchantData.value = res.data.data

        return res
      })
      .catch(notifyErrors)
      .finally(() => {
        loading.value = false
      })
  }

  return {
    loading,

    merchantData,

    loadMerchant,
  }
}
