<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <v-text-field
              v-model="form.shipping_fee"
              label="Shipping Fee"
              suffix="hkd"
              :rules="[between(form.shipping_fee, 0, 999)]"
            ></v-text-field>

            <v-switch
              v-model="form.enable_waive_fee"
              color="primary"
              :label="form.enable_waive_fee ? 'Enabled Free Shipping Fee Waive' : 'Enable Free Shipping Fee Waive'"
            ></v-switch>

            <v-text-field
              v-model="form.waived_at_subtotal"
              label="Enable Free Shipping (waive fee) if min order subtotal is"
              suffix="hkd"
              :rules="[between(form.waived_at_subtotal, 0, 99999)]"
              :disabled="!form.enable_waive_fee"
            ></v-text-field>

            <v-switch
              v-model="form.enable_pay_on_arrival"
              color="primary"
              :label="form.enable_pay_on_arrival ? 'Enabled Pay on Arrival' : 'Enable Pay on Arrival'"
            ></v-switch>

            <v-divider class="mb-5" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { between } from '@core/utils/validation';
import { } from '@mdi/js';
import { computed, ref, watch } from '@vue/composition-api';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      shipping_fee: props.resource ? props.resource.shipping_fee : null,
      waived_at_subtotal: props.resource ? props.resource.waived_at_subtotal : null,
      enable_pay_on_arrival: props.resource ? props.resource.enable_pay_on_arrival : false,
      enable_waive_fee: props.resource ? props.resource.enable_waive_fee : true,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) {
        const { value } = form
        value.shipping_fee = parseFloat(value.shipping_fee)
        value.waived_at_subtotal = parseFloat(value.waived_at_subtotal)
        value.enable_pay_on_arrival = !!value.enable_pay_on_arrival
        form.value = value
        emit('submit', form.value)
      }
    }

    watch(computed(() => form.value.enable_waive_fee), enabled => {
      if (!enabled) {
        form.value.waived_at_subtotal = 9999 // because PWC can only detect waived_at_subtotal
      }
    })

    return {
      form,
      formElem,
      validate,
      between,
    }
  },
}
</script>
